@font-face {
    font-family: 'MuseoSans';
    font-style: normal;
    font-weight: 400;
    src: url('Font/MuseoSansCyrl-300.otf') format("opentype");
}


body {
    margin: 0;
    padding: 0 40px;
    font-family: 'MuseoSans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fcfcfc;
    font-size: 14px;
}

main {
    position: relative;
}

#root {
    /* height: 100vh; */
    max-width: 1150px;
    margin: 0 auto;
}

button {
    cursor: pointer
}

div {
    box-sizing: border-box;
}

a, a:visited {
    color: #2395FF;
}

@media (max-width: 576px) {
    body {
        padding: 0 22px;
    }
}

@media (max-width: 576px) {
    body {
        padding: 0 22px;
    }
}

@media (max-width: 320px) {
    body {
        padding: 0;
    }
}